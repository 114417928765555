import React from "react";
import Slider, { Settings } from "react-slick";
import Skeleton from "@material-ui/lab/Skeleton";
import { styled, Box, Grid, Card, CardMedia, Typography } from "@material-ui/core";
import { ICarouselAttr } from "../../blocks/landingpage/src/LandingPageController";

interface CarouselState {
  currentSlide: number;
}

interface carouselList {
  id: string;
  type: string;
  attributes: ICarouselAttr;
}[];


// Define a type for the props
interface CarouselProps {
  data: carouselList[];
  onCarouselClick: (data: ICarouselAttr) => void
}


export class CustomLandingCarousel extends React.Component<CarouselProps, CarouselState> {
  slider: Slider | null = null;

  constructor(props: CarouselProps) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  settings: Settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2300,
    infinite: true,
    arrows: false,
    cssEase: "ease-in-out",
    afterChange: (current) => this.setState({ currentSlide: current }),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
    ]
  };



  renderDots = (data: carouselList[]) => {
    const dotCount = data.length > 2  ? data.length - 1 : data.length

    return dotCount > 1 && (
      <div className="custom-dots">
        {Array(dotCount)
          .fill(0)
          .map((_, index) => (
            <span
              key={index}
              className={`dot ${this.state.currentSlide === index ? "active" : ""}`}
              onClick={() => {
                this.setState({ currentSlide: index})
                
                  this.slider?.slickGoTo(index, true);
                }
              }
            ></span>
          ))}
      </div>
    );
  };

  renderSingleSlide = (data: carouselList[]) => {
    return <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
      <SingleCard>
        <Box style={{ position: 'relative' }} >
          {!data[0]?.attributes?.web_image ? (
            <Skeleton variant="rect" width="100%" height={300} />
          ) : (
            <CardMedia
              component={"img"}
              image={data[0]?.attributes?.web_image}
              alt={`Slide`}
              style={{
                position: "relative",
                display: "block",
                margin: "auto",
              }}
            />
          )}
        </Box>
        <TextContainer onClick={()=>this.props.onCarouselClick(data[0].attributes)}>
          <Typography className='carouselText2' >{data[0]?.attributes?.title}</Typography>
          <Typography className='carouselText3' >{data[0]?.attributes?.subtitle}</Typography>
          <Typography
            component="a"
            target="_blank" rel="noopener noreferrer"
            href={data[0]?.attributes?.link}
            className='carouselText'
          >
            View collection & Shop
          </Typography>
        </TextContainer>
      </SingleCard>
    </Box>
  }

  renderDoubleSlide = (data: carouselList[]) => {
    return <MainConatinerGrid>
    <Slider ref={(c) => (this.slider = c)} {...this.settings}>
      {data && data.map((item: carouselList, index: number) => (
        <Grid key={index} className={`item ${this.state.currentSlide === index ? "slide activeSlide" : "slide"}`}>
          <Card style={{ boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px" }}>
            <Box style={{ position: 'relative' }} key={index} >
              <CardMedia
                component={"img"}
                image={item?.attributes?.web_image}
                alt={`Slide ${index + 1}`}
                
                style={{ position: 'relative', display: 'block', margin: 'auto',
                  width: "100%",
                  height: "auto",
                  aspectRatio: "16 / 9",
                  objectFit: "cover",
                 }}
              />
            </Box>
            {this.state.currentSlide === index &&
              <TextContainer onClick={()=>this.props.onCarouselClick(item.attributes)}>
                <Typography className='carouselText2' >{item?.attributes?.title}</Typography>
                <Typography className='carouselText3' >{item?.attributes?.subtitle}</Typography>
                <Typography
                  component="a"
                  href={item?.attributes?.link}
                  className='carouselText'
                  target="_blank" rel="noopener noreferrer"
                >
                  View collection & Shop
                </Typography>
              </TextContainer>}
          </Card>
        </Grid>
      ))}
    </Slider>
    {this.renderDots(data)}
  </MainConatinerGrid>
  }

  renderSlides = () => {
    let {data} = this.props

    if(!data) return null
    data = data.length>2 ? [...data, data[data.length-1]] : data

    if(data.length === 1) {
      return this.renderSingleSlide(data)
    } else if(data.length >= 2){
      return this.renderDoubleSlide(data)
    } 
  }

  render() {
    return <>{this.renderSlides()}</>
  }
}


const SingleCard = styled(Card)({
  boxShadow: 'none',
  borderRadius: "0px, 0px, 2px, 2px",
  paddingBottom: "0 !important",
  height: 'fit-content !important',
  width: '100%',
  margin: '0 20px',
  minWidth: '330px',
  position: 'relative',
  maxWidth: "838px",
  "& img": {
    maxWidth: '100%',
    height: '311px',
    objectFit: "fill" as "fill",
    transition: 'transform 0.5s ease-in-out',
    "@media(max-width: 600px)": {
      height: '100%',
      maxheight: "250px",
    },
  }
})

const TextContainer = styled(Box)({
  padding: '20px', textAlign: 'center',
  cursor: "pointer",
  "& .carouselText": {
    color: "#375280",
    fontSize: "clamp(14px, 2vw, 20px)",
    fontWeight: 700,
    paddingTop: "5px",
    fontFamily: "Lato",
    textDecoration: 'underline',
    cursor: 'pointer',
    textUnderlineOffset: '4px',
  },
  "& .carouselText2": {
    color: "#375280",
    fontSize: "clamp(14px, 2vw, 20px)",
    fontWeight: 400,
    textAlign: "center" as "center",
    fontFamily: "Lato",
  },

  "& .carouselText3": {
    color: "#375280",
    fontSize: "clamp(18px, 3vw, 28px)",
    fontWeight: 800,
    textAlign: "center" as "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontFamily: "Lato",
  },
})

const MainConatinerGrid = styled(Grid)({
  "@media(max-width:2280px)": {
    margin: "0 -480px !important"
  },
  "@media(max-width:1840px)": {
    margin: "0 -400px !important"
  },
  "@media(max-width:1440px)": {
    margin: "0 -250px !important"
  },
  "@media(max-width: 600px)": {
    margin: "0 -280px !important"
  },
  "@media(max-width: 425px)": {
    margin: "0 -300px !important"
  },
  "@media(max-width: 360px)": {
    margin: "0 -325px !important"
  },
  "& .activeSlide": {
    transform: 'scale(1.4) !important',
    opacity: '1 !important',
    transition: 'transform 0.3s ease, opacity 0.3s ease',
  },
  "& .slide": {
    transform: 'scale(1.0) !important',
    opacity: '0.9 !important',
    transition: 'transform 0.3s ease, opacity 0.3s ease',
  },
  "& .slide img": {
    width: "100%", 
    height: "auto",
    maxHeight: '315px',
    objectFit: "fill" as "fill",
    transition: 'transform 0.3s ease-in-out',
    "@media(max-width: 600px)": {
      height: '100%',
      minWidth: '330px',
      maxWidth: "540px",
      maxheight: "230px",
    },
  },
  "& .activeSlide img": {
    width: "100%", 
    height: "auto",
    maxWidth: "960px",
    maxHeight: "450px",
    objectFit: "fill" as "fill",
    transition: 'transform 0.3s ease-in-out',
    "@media(max-width: 600px)": {
      height: '100%',
      maxWidth: "540px",
      minWidth: "330px",
      maxheight: "250px",
    },
  },
  "& .slide .MuiCard-root": {
    position: 'relative',
    height: '340px',
    paddingBottom: '40px',
    "@media(max-width: 940px)": {
      paddingBottom: '0px',
    },
    "@media(max-width: 600px)": {
      height: 'fit-content !important',
      paddingBottom: '40px',
    },
  },
  "& .activeSlide .MuiCard-root": {
    paddingBottom: "0 !important",
    height: 'fit-content !important',
    width: '100%',
    minWidth: '330px',
    position: 'relative',
    "@media(max-width:1440px)": {
      maxWidth: "838px",
    },
  },
  "& .slick-list": {
    padding: '20px 0 !important',
    height: 'fit-content'
  },
  "& .slick-prev": {
    left: '100px !important',
  },
  "& .slick-next": {
    right: '100px !important',
  },
  "& .slick-slide": {
    transition: "transform 0.4s ease-in-out, opacity 0.4s ease-in-out",
    outline: 'none !important'
  },
  "& .slick-slide:not(.slick-center) .MuiCard-root": {
    transform: 'scale(0.9) !important'
  },
  "& .custom-dots": {
    display: "flex",
    justifyContent: "center",
    marginTop: "26px",
    alignItems: "center",
    listStyleType: "none",
    padding: 0,
  },
  "& .dot": {
    width: "17px",
    height: "17px",
    borderRadius: "50%",
    backgroundColor: "#375280",
    opacity: "0.5",
    margin: "5px 9px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease"
  },
  "& .dot.active": {
    width: "18px",
    height: "18px",
    backgroundColor: "#375280",
    opacity: "1 !important"
  },
  "& .slick-dots li button::before": {
    fontSize: '21px !important',
    color: '#375280 !important',
  },
  "& .slick-prev::before": {
    color: 'red !important'
  },
  " &.slick-next::before": {
    color: 'red !important'
  },

});

export default CustomLandingCarousel;