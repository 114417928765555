// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { brandImage } from "./assets";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
import { apiCall } from "../../../components/src/APICall";
// Customizable Area End


export const configJSON = require("./config");

interface AnalyticsData {
  analyticsType: string,
  type: string
}

interface StoreReportProduct {
    filter_range: string,
    max_value: string,
    average_order_value: string,
    sales_volume: number,
    sold_units: 0,
    returned_units: 0,
    graph_data: {
        total_revenue: {
          "jan": number
        }
}
} 
interface VolumeReportStore {
    filter_range: string,
    selected_month: string,
    total_sold_units: number,
    sold_units: number,
    returned_units: number
}

interface WeeklyData {
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sun: string;
}

interface MonthlyData {
  Jan: number;
  Feb: number;
  Mar: number;
  Apr: number;
  May: number;
  Jun: number;
  Jul: number;
  Aug: number;
  Sep: number;
  Oct: number;
  Nov: number;
  Dec: number;
}

interface TotalRevenue {
  current_week: WeeklyData;
  previous_week: WeeklyData;
  current_month: MonthlyData;
  previous_month: MonthlyData;
}

interface GrowthResponse {
  selected_month: string;
  filter_range: string;
  sales_revenue: string;
  profit: boolean;
  loss: boolean;
  profit_percentage: string;
  loss_percentage: string;
  total_revenue: TotalRevenue;
}

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  analyticsData: AnalyticsData[],
  activePage: string,
  storeActivePage: string,
  companynameData: {companyIcon: string,
    companyName: string
  }[],
  growthGraphLoading: boolean;
  productChecked: string,
  growthLoading: boolean;
  storeChecked: string,
  growthSelectStore: boolean;
  growthSelectProduct: boolean;
  token: string;
  totalRevenue: string;
  storeReportProduct: StoreReportProduct;
  reportProductPeriod: string;
  volumeStore: VolumeReportStore;
  leftIndex: number;
  rightIndex: number;
  pieChartData : {
    label: string,
    value: number,
    color: string
}[];
growthResponse: any,
productSelected: any;
storeSelected: any;
catalogueProduct: any;
storesData: any;
checkedAllStore: string;
selectedMonth: string;
userRole: string;
storeLoading: boolean;
productLoading: boolean;
isProductView: boolean;
page: number;
perPage: number;
metaProductPage: number;
circularProgress: boolean;
exportAnalyticsDialog: boolean;
exportAnalyticsType: string;
isBackDropVolumeLoader: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTotalRevenueApiCallID: string = "";
  getSalesReportApiCallID: string = "";
  getSalesReportProductApiCallID: string = "";
  getVolumeReportStoreApiCallID: string = "";
  getVolStrDataMontAPICallID: string = "";
  getGrowthReportStoreApiCallID: string = "";
  getCatalogueProductCallID: string = "";
  getStoreCallID: string = "";
  getProductDetailsApiCallId: string = "";
  getCatalogueProductPagCallID: string = "";
  getDownloadTotalRevenueCallID: string = "";
  getDownloadSalesGrowthCallID: string = "";
  getDownloadSalesRevenueStoreCallID: string = "";
  getDownloadSalesRevenueProductCallID: string = "";
  getDownloadSalesVolumeStoreCallID: string = "";
  getDownloadSalesVolumeProductCallID: string = "";
  private debounceTimer: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isProductView: false,
      leftIndex: 0,
    rightIndex: 6,
    growthLoading: false,
    storeLoading: false,
    productLoading: false,
    metaProductPage: 0,
    page: 1,
    perPage: 10,
    isBackDropVolumeLoader: false,
      analyticsData: [{
        analyticsType: "Sales Revenue Report",
        type: "store"
      },{
        analyticsType: "Sales Volume Report Store",
        type: "volume"

      },{
        analyticsType: "Sales Volume Report Product",
        type: "volumeProduct"

      },{
        analyticsType: "Sales Growth Report",
        type: "growth"

      }],
      activePage: "",
      storeActivePage: "salesStore",
      companynameData: [{
        companyIcon: brandImage,
        companyName: "Fashion Fusion Hub"
      },{
        companyIcon: brandImage,
        companyName: "Trendy"
      },{
        companyIcon: brandImage,
        companyName: "H&M"
      },{
        companyIcon: brandImage,
        companyName: "Zara"
      },{
        companyIcon: brandImage,
        companyName: "The Souled Store"
      }],
      growthGraphLoading: false,
      productChecked:"",
      storeChecked: "",
      growthSelectStore: false,
      growthSelectProduct: false,
      userRole: "",
      token: "",
      totalRevenue: "0",
      storeReportProduct: {filter_range: "",
      max_value: "",
      average_order_value: "",
      sales_volume: 0,
      sold_units: 0,
      returned_units: 0,
      graph_data: {
          total_revenue: {
            "jan": 0
          }
      }},
      growthResponse: {},
  reportProductPeriod: "monthly",
  volumeStore: {
    filter_range: "",
    selected_month: "",
    total_sold_units: 0,
    sold_units: 0,
    returned_units: 0
    },
    pieChartData: [{
      label: "Units Sold",
      value: 50,
      color: "#375280"
  },
  {
      label: "Returned Units",
      value: 20,
      color: "#F59E0B", 
  },
  ],
  productSelected: {},
  storeSelected: {},
  catalogueProduct: [],
  circularProgress: false,
  storesData: [],
  checkedAllStore: "true",
  selectedMonth: "",
  exportAnalyticsDialog: false,
  exportAnalyticsType: "pdf",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    await this.anotherPageProps(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
     
      if (apiRequestCallId === this.getTotalRevenueApiCallID) {
        if(responseJson?.data){
          this.setState({
           totalRevenue: responseJson?.data?.total_revenue
          })
        }
      }else if(apiRequestCallId === this.getSalesReportApiCallID){
        if(responseJson?.data){
          this.setState({
            storeReportProduct: responseJson.data
          });
          this.getGraphData(responseJson?.data?.graph_data?.total_revenue)
        }
      }else if(apiRequestCallId === this.getVolumeReportStoreApiCallID){
        if(responseJson?.data){
          const updatedData =  [{
            label: "Units Sold",
            value: responseJson.data.sold_units,
            color: "#375280"
        },
        {
            label: "Returned Units",
            value: responseJson.data.returned_units,
            color: "#F59E0B", 
        },
        ]
          this.setState((prevState)=>({
            volumeStore: responseJson?.data,
            pieChartData: updatedData,
          }))
        }
    }   else {
        await this.handleApiCall(apiRequestCallId, message)
        this.handleProductDetailsApiCall(apiRequestCallId, message)
    } 
      }
    // Customizable Area End
  }
  // Customizable Area Start
  anotherPageProps = async (message: Message) => {
  if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
     
    const tabValue = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    this.setState((prevState) => ({
      ...prevState,
      storeActivePage: "salesStore",
      activePage: tabValue.activePage,
      storeChecked: tabValue.storeId,
      isBackDropVolumeLoader: true
    }),async ()=>{
      await this.getStores("");
    });
  }
}
  componentDidUpdate(prevProps: Props, prevState: S) {
    if(prevState.storeSelected !== this.state.storeSelected){
      this.setState({isBackDropVolumeLoader: false})
    }
  }
  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("auth-token");
    const userData = await getStorageData('userRole', true);
    const id = this.props.navigation.getParam("id")
    
    this.setState(
      { token, userRole: userData.userType },
      () => id ? this.handleProductView(id) : null
    );
   this.setState({leftIndex: new Date().getMonth() > 6 ? 6 : 0, rightIndex: new Date().getMonth()> 6 ? 12 : 6})
   if(await getStorageData("anotherPage")==="anotherPage") return
   this.getTotalRevenue(token);
   this.getSalesReport("monthly");
   this.getVolumeStore("monthly");
   this.getGrowthStore("monthly");
    // Customizable Area End
  }

  handleDownLoadAnalysis = async (messages: Message) => {
    const apiIDMessaage = messages.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === messages.id && (
        this.getDownloadTotalRevenueCallID === apiIDMessaage ||
        this.getDownloadSalesGrowthCallID === apiIDMessaage ||
        this.getDownloadSalesRevenueStoreCallID === apiIDMessaage ||
        this.getDownloadSalesRevenueProductCallID === apiIDMessaage ||
        this.getDownloadSalesVolumeStoreCallID === apiIDMessaage ||
        this.getDownloadSalesVolumeProductCallID === apiIDMessaage
    )) {
      const responseJson = await messages.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.openInNewTab(responseJson.url);
      } else if (responseJson && responseJson.errors) {
        this.handleInvalidToken(responseJson.errors[0].token);
      }
    }
  };

  handleInvalidToken = (messageText: string) =>{
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true, alertMsg: messageText, alertType: 'error' 
    }))
  }

  handleProductView(id: string) {
    this.handlePageChange("volumeProduct");
    this.setState(
      { productChecked: id, isProductView: true },
      () => this.getProductDetails(id)
    );
  }

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;

    if(this.state.metaProductPage >= this.state.page && (dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight)){
        this.getProductCataloguePagination();
    };
  };

  handleBackProductView = async () => {
    await this.storeProfile();
    this.state.isProductView && this.handleRedirect("ProductDetail")
    this.setState({activePage: "", storeSelected: {}, productChecked: ""})
  }

  handleProductDetailsApiCall = (apiRequestCallId : string, message: Message) => {
    let responseJs = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getProductDetailsApiCallId){
      if(responseJs?.data){
        this.setState({
          productSelected: responseJs.data
        }, () => {
          this.getSalesVolumeProduct();
        });
      }
    }else if(apiRequestCallId === this.getCatalogueProductPagCallID){
      if(responseJs?.data){
        const arrTrendList = [...this.state.catalogueProduct, ...responseJs.data];
        this.setState((prev: any) => {
          return {
            catalogueProduct: [...arrTrendList],
           page: prev.page + 1,
           circularProgress: false,
       }
    });     
    }
    }
  }

  getGraphData = (totalRevenue: any) => {
    if(Object.keys(totalRevenue).length > 1){
      const graphData = Object.values(totalRevenue);
      return graphData
    }
    return [0]
  };

  handleApiCall = async (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    switch (apiRequestCallId) {
      case this.getCatalogueProductCallID:
        if (responseJson?.data) {
          this.setState({
            catalogueProduct: responseJson.data,
            productLoading: false,
            page: this.state.page + 1,
            metaProductPage: responseJson?.meta?.total_pages,
          });
        } else if (responseJson?.errors) {
          this.setState({
            catalogueProduct: [],
            productLoading: false,
            page: 1,
          });
        }
        this.setState({ productLoading: false });
        break;
  
      case this.getStoreCallID:
        if (responseJson?.data) {
          this.setState({
            storesData: responseJson?.data,
            storeLoading: false,
          }, async() => {

          if ((await getStorageData("anotherPage")) === "anotherPage") {
            this.handleAnotherPageResp()
          }
          });
        }
        this.setState({ storeLoading: false });
        break;
  
      case this.getSalesReportProductApiCallID:
        if (responseJson?.data) {
          this.setState({
            storeReportProduct: responseJson.data,
          });
          this.getGraphData(responseJson?.data?.graph_data?.total_revenue);
        }
        break;
  
      case this.getGrowthReportStoreApiCallID:
        this.setState({
          growthResponse: responseJson.data,
          growthLoading: false,
          growthGraphLoading: false,
        });
        break;
  
      case this.getVolStrDataMontAPICallID:
        if (responseJson?.data) {
          const updatedData = [
            {
              label: "Units Sold",
              value: responseJson.data.sold_units,
              color: "#375280",
            },
            {
              label: "Returned Units",
              value: responseJson.data.returned_units,
              color: "#F59E0B",
            },
          ];
          this.setState((prevS) => ({
            volumeStore: responseJson?.data,
            pieChartData: updatedData,
          }));
        }
        break;
  
      default:
        await this.handleDownLoadAnalysis(message);
        break;
    }
  };

  handleAnotherPageResp = () => {
    const storeData = this.state.storesData.find((element: any) => element.id === this.state.storeChecked);
    if(storeData || this.state.checkedAllStore == "true"){
      this.setState({
        storeSelected: storeData, checkedAllStore: ""
      });
      if(this.state.activePage === "store"){
        this.getSalesReportStore();
      }
    }
  }
  
  changeSearch = (value: string) => {
    this.getStores(value)
  }
  handleCondition = (condition: any, trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta
  } 
  getStores = async(searchValue?: string) => {

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    this.setState({
      storeLoading: true
    })
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
        
    const storeUrl = searchValue ? `${configJSON.getStoreEndPoint}?approved=true&search=${searchValue}`: `${configJSON.getStoreEndPoint}?approved=true`
    this.getStoreCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        storeUrl
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  getProductCatalogue = async(searchValue?: string) => {
    const {page, perPage} = this.state;
    this.setState({productLoading: true})
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
        const productCalalogue = searchValue ? `${configJSON.getCatalogueProductsEndPoint}?my_catalogues=true&search=${searchValue}` : 
        `${configJSON.getCatalogueProductsEndPoint}?my_catalogues=true&page=${page}&per_page=${perPage}`
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueProductCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),productCalalogue
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getProductCataloguePagination = async(searchValue?: string) => {
    const {page, perPage} = this.state;
    this.setState({circularProgress: true})
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
        const productCalalogue = searchValue ? `${configJSON.getCatalogueProductsEndPoint}?my_catalogues=true&search=${searchValue}` : 
        `${configJSON.getCatalogueProductsEndPoint}?my_catalogues=true&page=${page}&per_page=${perPage}`
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueProductPagCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),productCalalogue
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getGraphDataDirect = () => {
    if(Object.keys(this.state.storeReportProduct.graph_data.total_revenue).length > 1){
      const graphData = Object.values(this.state.storeReportProduct.graph_data.total_revenue);
      return graphData
    }
    return [0]
  };

  getCurrentMonthDate = (altMonth?: number) => {
    const year = new Date().getFullYear();
    const month = altMonth ? altMonth : new Date().getMonth() + 1;
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString())
  }

  getGrowthdateCurreValues = (monthName: string) => {
    const sortedPairs = this.state.growthResponse.total_revenue.current_month && Object.entries(this.state.growthResponse.total_revenue.current_month[monthName]).sort((a, b) => Number(a[0]) - Number(b[0]));
    const secondIndexValues = sortedPairs.map((item: any) => item[1]);
    return secondIndexValues as number[]
  }

  getGrowthdatePreValues = (monthName: string) => {
    const sortedPairs = this.state.growthResponse.total_revenue.previous_month && Object.entries(this.state.growthResponse.total_revenue.previous_month[monthName]).sort((a, b) => Number(a[0]) - Number(b[0]));
    const secondIndexValues = sortedPairs.map((item: any) => item[1]);
    return secondIndexValues as number[]
  }

  getGraphDataGrowth = (type: string) => {
    if(type === "current" && this.state.growthResponse && Object.keys(this.state.growthResponse)?.length > 1){
      const currMon = this.state.growthResponse?.total_revenue?.current_month && Object.keys(this.state.growthResponse.total_revenue?.current_month);
      if(currMon?.length > 0){
        return this.getGrowthdateCurreValues(currMon[0]);
      }
    }else if(type === "prev" && this.state.growthResponse &&  Object.keys(this.state.growthResponse).length > 1){
      const prevMon = this.state.growthResponse?.total_revenue?.previous_month && Object.keys(this.state.growthResponse.total_revenue?.previous_month);
      if(prevMon?.length > 0){
        return this.getGrowthdatePreValues(prevMon[0]);
      }
    }
    return [0]
  }

  getGraphDataGrowthWeekly = (type: string) => {
    if(type === "current" && this.state.growthResponse &&  Object.keys(this.state.growthResponse).length > 1 ){
        const arrayObj = this.state.growthResponse?.total_revenue?.current_week && Object.keys(this.state.growthResponse.total_revenue?.current_week);
        if(arrayObj?.length > 0){
            const graphData = Object.values(this.state.growthResponse.total_revenue.current_week[arrayObj[0]]);
            return graphData as number[]
          }
        }else if(type === "prev" && this.state.growthResponse && Object.keys(this.state.growthResponse).length > 1){
        const arrayObj = this.state.growthResponse?.total_revenue?.previous_week && Object.keys(this.state.growthResponse?.total_revenue?.previous_week);
      if(arrayObj?.length > 0){
        const graphData = Object.values(this.state.growthResponse.total_revenue.previous_week[arrayObj[0]]);
        return graphData as number[]
      }
    }
    return [0]
  }
  handleArrowClick = (type: string) => {
    if(type === 'left') {
      this.setState({leftIndex: 0, rightIndex: 6})
    }
    if(type === 'right') {
      this.setState({leftIndex: 6, rightIndex: 12})
    }
  }
  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  handleSelectStoreClose = () => {
    this.setState({
      growthSelectStore: false
    })
  };

  handlePeriodChangeStore = (value: string) => {
    this.setState({
      reportProductPeriod: value
    }, () => (this.state.storeActivePage === "salesStore" ?  this.getSalesReportStore() :  this.getSalesReportProduct()));
  }

  handlePeriodChangeVolume = (value: string) => {
    this.setState({
      reportProductPeriod: value, selectedMonth: ""
    }, () => this.state.activePage === "volume"? this.getVolumeStoreId(): this);
    this.getVolumeStoreId()
  }

  handlePeriodChangeGrowth = (value: string) => {
    this.setState({
      reportProductPeriod: value
    });
    this.getGrowthStore(value)
  }

  getTotalRevenue = async(Authtoken: string) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: Authtoken,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalRevenueApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTotalRevenueEndPoint}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesReport = async(filterRange: string) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSalesReportApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSalesReportProductEndPoint}?filter_range=${filterRange}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getSalesReportProduct = async() => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSalesReportProductApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSalesReportProdEndPoint}${this.state.productChecked}?filter_range=${this.state.reportProductPeriod}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesVolumeProduct = async() => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreProdEndPoint}${this.state.productChecked}?filter_range=${this.state.reportProductPeriod}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesVolumeProductAccMonth = async(month: string) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const selectedMMonth = configJSON.monthLabel.indexOf(month);
    this.setState({selectedMonth: configJSON.months[selectedMMonth]}); 

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreProdEndPoint}${this.state.productChecked}?filter_range=${this.state.reportProductPeriod}&month=${configJSON.months[selectedMMonth]}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesReportStore = async() => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSalesReportApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSalesReportProductEndPoint}?filter_range=${this.state.reportProductPeriod}&id=${this.state.storeChecked}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getGrowthStoreAccMonth = async(month?:string) => {
    const selectedMMonth = configJSON.monthLabel.indexOf(month);
    this.setState({selectedMonth: configJSON.months[selectedMMonth]}); 

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGrowthReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getGrowthStoreEndPoint}?filter_range=${this.state.reportProductPeriod}&month=${configJSON.months[selectedMMonth]}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getGrowthStore = async(filterRange: string) => {
    const currentMonth = new Date().getMonth();
    
    this.setState({
      growthLoading: true 
    })
    const activeMonth = this.state.selectedMonth ? this.state.selectedMonth : configJSON.monthLabel[currentMonth];

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGrowthReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getGrowthStoreEndPoint}?filter_range=${filterRange}&month=${activeMonth}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getVolumeStore = async(filterRange: string) => {
    const currentMonth = new Date().getMonth();
    const activeMonth = this.state.selectedMonth ? this.state.selectedMonth : configJSON.monthLabel[currentMonth];

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreEndPoint}?filter_range=${filterRange}&month=${activeMonth}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getVolStrDataAccToMonth = async( month: string) => {
    const selectedMMonth = configJSON.monthLabel.indexOf(month);
    this.setState({selectedMonth: configJSON.months[selectedMMonth]});    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolStrDataMontAPICallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreEndPoint}?filter_range=${this.state.reportProductPeriod}&month=${configJSON.months[selectedMMonth]}&id=${this.state.storeChecked}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getVolumeStoreId = async() => {
    const currentMonth = new Date().getMonth();
    const activeMonth = this.state.selectedMonth ? this.state.selectedMonth : configJSON.monthLabel[currentMonth];
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreEndPoint}?filter_range=${this.state.reportProductPeriod}&month=${activeMonth}&id=${this.state.storeChecked}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  handleGrowthDialogOpen = () => {
    this.getStores();
    this.setState({growthSelectStore: true});
  }

  handleStoreDialogOpen = () => {
    this.getProductCatalogue();
    this.setState({growthSelectProduct: true});
  }

  handlegrowthSelectProduct = () => {
    this.setState({
      growthSelectProduct: false, catalogueProduct: [], page: 1
    })
  }

  changeProductSearch = (value: string) => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.setState({
        page: 1
      }, () => this.getProductCatalogue(value))
    }, 300);
  }

  handlePageChange = (active: string) => {
    if( active === "volumeProduct"){
      this.setState({storeActivePage: "salesProduct", activePage: active})
      this.getVolumeStore("monthly")
    }else{
      this.setState({
        activePage: active,
        storeActivePage: "salesStore"
      })
    }

    if(active === "store"){
      this.setState({
        productSelected: {},
        storeChecked: "",
        storeActivePage: this.state.userRole === "stylist" ? "salesProduct" : "salesStore",
        checkedAllStore: "true"
      }, () => {this.getSalesReport("monthly")})
    }else if(active === "volume" || active === "volumeProduct"){
      this.setState({
        productSelected: {},
        storeChecked: "",
        checkedAllStore: "true"
      }, () => {this.getVolumeStore("monthly")})
      
    }else if(active === "growth"){
      this.getGrowthStore("monthly");
    }
  };

  onChangeHandler = (value: string) => {
   this.setState({ productChecked: value});
  };

  handleStoreSubmit = () => {
    const storeData = this.state.storesData.find((element: any) => element.id === this.state.storeChecked);
    if(storeData || this.state.checkedAllStore == "true"){
      this.setState({
        growthSelectStore:false,
        storeSelected: storeData, checkedAllStore: ""
      });
      if(this.state.activePage === "store"){
        this.getSalesReportStore();
      }

      if(this.state.activePage === "volume"){
        this.getVolumeStoreId();
      }
    }
  }
   
  onChangeHandlerStore = (value: string) => {
    this.setState({
      storeChecked: value,
      checkedAllStore: ""
    })
  };

  handleAllSelectStore  = (value: string) => {
    this.setState({
      checkedAllStore: value,
      storeChecked: "",
    })
  }

  handleClickMonth = (month: string, tabFrom: string) => {
    if(tabFrom === "volumeProduct"){
      this.getSalesVolumeProductAccMonth(month)
    }else{
      this.getVolStrDataAccToMonth(month);
    }
  }

  handleGrowthMOnth = (value: string) =>{
    this.setState({
      selectedMonth: value,
      growthGraphLoading: true
    }, () => this.getGrowthStoreAccMonth(value))
  }

  handleProductSelect = () => {
    const productReport = this.state.catalogueProduct.find((element: any)=> element.id === this.state.productChecked);
    if(productReport){
      this.setState({
        productSelected: productReport,
        growthSelectProduct: false
      });
      if(this.state.activePage === "store"){
        this.getSalesReportProduct();
      }
      if(this.state.activePage === "volumeProduct"){
       this.getSalesVolumeProduct();
      }
    }
  }
  
  handleStoreTab = (path: string) => {
    this.setState({
      storeActivePage: path
    });
  }

  getProductDetails = async (productId:any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCatalogueDetail}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  transAnalytics = (anaKey: string) => {
    return i18n.t(anaKey, {ns: "analytics"})
  }

  handleExportAnalyticsDialogClose = () => {
    this.setState({
      exportAnalyticsDialog: false,
      exportAnalyticsType: "pdf"
    });
  };

  handleExportAnalyticsDialogOpen = () => {
    this.setState({
      exportAnalyticsDialog: true
    });
  };

  onChangeExport = (value: string) => {
    this.setState({ exportAnalyticsType: value });
  };

  openInNewTab = (url: string) => {
    if (url.includes('_doc')) {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'download_report_sales_product.doc';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  getDownloadTotalRevenueCalled = async () => {
    this.getDownloadTotalRevenueCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getDownloadTotalRevenueEndPoint}${this.state.exportAnalyticsType}`,
      token: this.state.token,
    });
  };

  getDownloadSalesGrowthCalled = async () => {
    this.getDownloadSalesGrowthCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getDownloadSalesGrowthEndPoint}${this.state.exportAnalyticsType}&month=${this.state.selectedMonth}`,
      token: this.state.token,
    });
  };

  getDownloadSalesRevenueStoreCalled = async () => {
    this.getDownloadSalesRevenueStoreCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getDownloadSalesRevenueStoreEndPoint}${this.state.exportAnalyticsType}&id=${this.state.productChecked}`,
      token: this.state.token,
    });
  };

  getDownloadSalesRevenueProductCalled = async () => {
    this.getDownloadSalesRevenueProductCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getDownloadSalesRevenueProductEndPoint}${this.state.productChecked}?type=${this.state.exportAnalyticsType}`,
      token: this.state.token,
    });
  };

  getDownloadSalesVolumeStoreCalled = async () => {
    this.getDownloadSalesVolumeStoreCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getDownloadSalesVolumeStoreEndPoint}${this.state.exportAnalyticsType}&id=${this.state.storeChecked}`,
      token: this.state.token,
    });
  };

  getDownloadSalesVolumeProductCalled = async () => {
    this.getDownloadSalesVolumeProductCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getDownloadSalesVolumeProductEndPoint}${this.state.productChecked}?type=${this.state.exportAnalyticsType}`,
      token: this.state.token,
    });
  };

  handleDownLoad = async () =>{
    const { activePage, storeActivePage } = this.state;
    switch (activePage) {
      case "":
        await this.getDownloadTotalRevenueCalled();
        break;
      case "growth":
        await this.getDownloadSalesGrowthCalled();
        break;
      case "volume":
        await this.getDownloadSalesVolumeStoreCalled();
        break;
      case "volumeProduct":
        await this.getDownloadSalesVolumeProductCalled();
        break;
      case "store": 
      storeActivePage === "salesStore" ? this.getDownloadSalesRevenueStoreCalled() : this.getDownloadSalesRevenueProductCalled();
    }
    this.handleExportAnalyticsDialogClose()
  }
  handleBack = async () =>{
    await this.storeProfile();
  this.setState({activePage: "", productSelected: {}, productChecked: "", storeSelected: {}})
  }

  storeProfile = async () =>{
    const anotherPage = await getStorageData("anotherPage")
  if(anotherPage === "anotherPage"){
    await removeStorageData("anotherPage");
    this.handleRedirect("SellerStoreProfile")
  }
  }
  // Customizable Area End
}
// Customizable Area Start

export const saleCount = [
    {label:"Total Sales",value:"$ 24,00"},
    {label:"Impression",value:"300"},
    {label:"Total Orders",value:"350"},
    {label:"Visitors",value:"3000"}
]

export const pieChartData = [{
    label: "Units Sold",
    value: 50,
    color: "#375280"
},
{
    label: "Returned Units",
    value: 20,
    color: "#F59E0B", 
},
]

export const lineChartData = [{
    label: "Current Period",
    color: "#059669", 
},
{
    label: "Last Period",
    color: "#F87171", 
}
]

export const monthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
// Customizable Area End
